.btn{
    margin-right: 50px;
    font-size: 40px;
}
.CurrentWord{
    font-size: 100px;
    font-weight: bold;
    letter-spacing: 5px;
    
}
.show-wrapper{
    display:block;
}
.hide-wrapper{
    display:none;
}


.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid rgb(229, 172, 182);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(229, 172, 182) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .card{
    margin:20px;
  }
  .card-header{
    background-color:#fff;
    margin:20px;
  }
  .wordImage{
    max-height: 200px;
  }
.vocabulary{
  font-size: 2vw;
}

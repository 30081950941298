.homeComponent{
    margin: auto;
    max-width: 800px;
    padding: 10px;
}
.wordImage{
    max-height: 200px;
}
.sayWord{
    font-size:calc(100% + 18vw);
    font-weight: bold;
    letter-spacing: 10px;
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    white-space: nowrap;
}
.wordContainerOptions{
    
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: rgb(246, 245, 248);
}
.RepeatWord {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 40px;
    cursor: pointer;
}
.RevealWord {
    position: absolute;
    top: 60px;
    left: 10px;
    font-size: 40px;
    cursor: pointer;
}
.selectButton{
    font-size:8vw;
    font-weight: bold;
}